<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
  >
    <v-card>
      <v-toolbar flat color="grey lighten-2">
        <v-toolbar-title>
          Modelos de Formulários
        </v-toolbar-title>
        <v-toolbar-items class="flex-grow-1 d-flex align-center">
          <v-spacer />
          <div>
            <v-btn
              color="primary" depressed
              class="mr-4"
              @click="$refs.formCreatorDialog.open('template')"
            >
              Novo Modelo
            </v-btn>
          </div>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="loading"
        loading-text="Carregando... Por favor, aguarde"
        no-data-text="Nenhum modelo cadastrado"
        no-results-text="Nenhuma modelo encontrado"
        :items="formTemplates"
        :headers="headers"
        disable-pagination
        hide-default-footer
        fixed-header
        height="600px"
      >
        <template #[`item.actions`]="{ item }">
          <v-tooltip bottom :open-delay="500">
            <template v-slot:activator="{ on }">
              <v-btn
                color="blue"
                class="ml-2"
                icon
                v-on="on"
                @click="$refs.formCreatorDialog.open('template', item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar Modelo</span>
          </v-tooltip>
          <!-- <template v-if="!item.have_children">
            <v-tooltip bottom :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="error"
                  class="ml-2"
                  v-on="on"
                  @click="deleteForm(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Deletar Modelo</span>
            </v-tooltip>
          </template> -->
        </template>
      </v-data-table>
    </v-card>
    <FormCreatorDialog
      ref="formCreatorDialog"
      @updated="loadTemplates()"
    />
  </v-dialog>
</template>

<script>
import FormCreatorDialog from "@/components/form_requests/FormCreatorDialog.vue";

export default {
  name: "FormTemplatesDialog",
  components:{
    FormCreatorDialog,
  },
  data: () => ({
    dialog: false,
    formTemplates: [],
    loading: false,
    headers: [
      { text: "Nome", value: "title" },
      { text: "", value: "actions", sortable: false, align: "right"},
    ],
  }),

  methods: {
    open() {
      this.dialog = true;
      this.loadTemplates();
    },
    loadTemplates() {
      this.loading = true;
      this.$axios
        .get("/form_requests/templates")
        .then((response) => {
          this.formTemplates = response.data;
        })
        .catch((error) => {
          this.$showMessage("error", "Não foi possivel carregar os modelos");
          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteForm(i){}, //Não existe end-point
  },
};
</script>
