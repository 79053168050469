<template>
  <v-dialog
    v-model="dialog"
    :width="4+8 + (cardWidth+4)*cardsHorizontal + scrollBarWidth"
  >
    <v-card>
      <v-toolbar
        color="primary white--text"
        max-height="65px"
      >
        <v-toolbar-title>
          Novo Formulário
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mr-3"
          color="primary darken-2"
          depressed
          @click="openTemplates()"
        >
          Gerenciar Modelos
        </v-btn>
        <v-toolbar-items class="align-center">
          <div>
            <v-btn color="white" icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar-items>
      </v-toolbar>
      <v-sheet
        color="grey lighten-2"
        class="pl-1 pt-1 pr-2 pb-2"
        :max-height="maxHeight"
        height="auto"
        style="overflow: auto"
      >
        <v-slide-y-transition
          leave-absolute group mode="out-in"
          tag="div"
        >
          <div
            key="loading"
            v-if="loading"
            class="
              flex-grow-1
              d-flex flex-column justify-center
              px-4
            "
            :style="{height: cardHeight+'px'}"
          >
            <div class="text-body-1 text-center font-weight-medium mt-n4">
              Carregando Modelos
            </div>
            <v-progress-linear class="mt-4" height="4px" indeterminate />
          </div>
          <div
            key="templates"
            v-else
            class="d-flex flex-wrap"
          >
            <v-card
              v-for="template in templates"
              :key="template.id"
              :color="
                template.id == -1 ? 'grey lighten-3'
                : 'primary darken-1 white--text'
              "
              class="
                mt-1 ml-1 pa-4
                text-center text-body-1 font-weight-medium
                d-flex align-center justify-center
              "
              :elevation="2"
              @click="openForm(template.id)"
              :width="cardWidth"
              :height="cardHeight"
            >
              {{ template.title }}
            </v-card>
          </div>
        </v-slide-y-transition>
      </v-sheet>
    </v-card>
    <FormCreatorDialog
      ref="formCreatorDialog"
      @updated="$emit('updated')"
      @created="$emit('created', $event)"
    />
    <FormTemplatesDialog ref="formTemplatesDialog" />
  </v-dialog>
</template>

<script>
import FormCreatorDialog from "@/components/form_requests/FormCreatorDialog.vue";
import FormTemplatesDialog from "@/components/form_requests/FormTemplatesDialog.vue";

export default {
  name: "newFormDialog",
  components: {
    FormCreatorDialog,
    FormTemplatesDialog,
  },

  data: () => ({
    dialog: false,
    templates: [],
    loading: false,
    cardWidth: 200,
    cardHeight: 150,
    cardsHorizontal: 3,
    cardsVertical: 3.5,
    formType: 'requested',
  }),

  computed: {
    scrollBarWidth(){
      const nLines = this.templates.length / this.cardsHorizontal;
      if (Math.ceil(nLines) <= Math.floor(this.cardsVertical)){
        return 0;
      }
      // copied from https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
      // Creating invisible container
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; // forcing scrollbar to appear
      outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
      document.body.appendChild(outer);
      // Creating inner element and placing it in the container
      const inner = document.createElement('div');
      outer.appendChild(inner);
      // Calculating difference between container's full width and the child width
      const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
      // Removing temporary elements from the DOM
      outer.parentNode.removeChild(outer);
      return scrollbarWidth;
    },
    maxHeight(){
      return (this.cardHeight*this.cardsVertical + 8*2) +'px';
    },
    minHeight(){
      return (this.cardHeight + 8*2) +'px';
    },
  },

  methods: {
    open(formType) {
      this.formType = formType;
      this.dialog = true;
      this.getTemplates();
    },
    openForm(id) {
      if (id == -1){
        id = undefined;
      }
      this.$refs.formCreatorDialog.open(this.formType, id, true);
      this.dialog = false;
    },
    openTemplates(){
      this.dialog = false;
      this.$refs.formTemplatesDialog.open();
    },
    getTemplates() {
      this.loading = true;
      this.$axios
        .get("/form_requests/templates")
        .then((response) => {
          this.templates = [
            {id: -1, title: "Começar sem Modelo"},
            ...response.data,
          ];
        })
        .catch((error) => {
          console.log(error);
          this.$showMessage("error", "Não foi possivel carregar os modelos");
          this.dialog = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
        });
    },
  },

  watch: {
    dialog(isShowing, wasShowing){
      if (wasShowing && !isShowing){
        setTimeout(() => {
          this.templates = [];
        }, 200);
      }
    },
  },

};
</script>
