<template>
  <div>
    <div class="pa-4 d-flex align-center">
      <h2>
        Formulários Recorrentes
      </h2>
      <v-spacer />
      <FormFilter v-model="filters" :withStatus="false" />
      <v-btn color="secondary" @click="$refs.newFormDialog.open('recurrent')">
        Nova Recorrência
      </v-btn>
    </div>
    <v-divider />
    <v-data-table
      :height="contentHeight-69"
      :loading="loading"
      :headers="headers"
      :items="recurrences"
      disable-pagination
      loading-text="Carregando... Por favor, aguarde"
      hide-default-footer
      fixed-header
      style="cursor: pointer"
      @click:row="$refs.formDialog.open($event.id)"
    >
      <template #no-data>
        <div v-if="tryAgain" class="py-5 d-flex flex-column align-center black--text">
          Erro ao carregar recorrências
          <br/>
          <v-btn small depressed color="error" class="mt-4" @click="tryAgain = false">
            tentar novamente
          </v-btn>
        </div>
        <template v-else>
          Nenhuma recorrência encontrada
        </template>
      </template>
      
      <template v-slot:[`item.categories`]="{ item }">
        <DocCategoryChips :categories="item.categories" />
      </template>

      <template v-slot:[`item.creation_datetime`]="{ item }">
        {{ $utils.formatDate(item.creation_datetime) }}
      </template>

      <template v-slot:[`item.last_sent`]="{ item }">
        {{ item.last_sent ? $utils.formatDate(item.last_sent) : "-" }}
      </template>

      <template v-slot:[`item.next_scheduled`]="{ item }">
        {{ $utils.formatDate(item.next_scheduled) }}
      </template>


      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          label
          small
          :color="getStatusColor(item)"
          style="width: 100px"
          class="justify-center align-center font-weight-medium"
        >
          {{ getStatusText(item) }}
          <template v-if="item.status == 'pending'">
            {{ item.current_question + "/" + item.total_questions }}
          </template>
        </v-chip>
      </template>

      <template #[`body.append`]>
        <tr v-if="!fullyLoaded && !tryAgain && started">
          <td :colspan="headers.length">
            <scroll-loader
              :loader-method="loadRecurrences"
              :loader-disable="fullyLoaded"
            >
              <v-progress-circular
                indeterminate
                :size="20"
                :width="2"
              />
            </scroll-loader>
          </td>
        </tr>
      </template>

    </v-data-table>
    <RequestDialog
      ref="formDialog"
      recurrent
      @updated="resetLoader()"
    />
    <NewFormDialog
      ref="newFormDialog"
      @updated="resetLoader()"
      @created="$refs.requestDialog.open($event)"
    />
  </div>
</template>

<script>
import DocCategoryChips from '@/components/documents/DocCategoryChips.vue';
import NewFormDialog from '@/components/form_requests/NewFormDialog.vue'
import RequestDialog from '@/components/form_requests/RequestDialog.vue'
import FormFilter from '@/components/form_requests/FormFilter.vue'

export default {
  name: "FormRecurrences",
  
  components: {
    DocCategoryChips,
    NewFormDialog,
    RequestDialog,
    FormFilter,
  },

  props: ['contentHeight'],

  data: () => ({
    started: false,
    headers: [
      { sortable: false, text: "Cliente", value: 'client.name' },
      { sortable: false, text: "Titulo", value: 'title' },
      { sortable: false, text: "Categorias", value: 'categories' },
      { sortable: false, text: "Criado em", value: 'creation_datetime' },
      { sortable: false, text: "Último criado", value: 'last_sent' },
      { sortable: false, text: "Dia da Recorrência", value: 'schedule_day' },
      { sortable: false, text: "Próxima Recorrência", value: 'next_scheduled' },
      { sortable: false, value: 'status' },
    ],
    recurrences: [],
    filters: {},
    limit: 30,
    fullyLoaded: false,
    changeTimeout: undefined,
    tryAgain: false,
    loading: false,
  }),

  methods: {
    getStatusColor(request) {
      switch (request.status){
        case "disabled": return "grey lighten-1";
        case "active": return 'success';
      }
      return 'grey';
    },
    getStatusText(request) {
      switch (request.status){
        case 'disabled': return "Desativado";
        case 'active': return "Ativo";
      }
    },
    resetLoader() {
      this.started = true;
      if (this.changeTimeout != undefined) {
        clearTimeout(this.changeTimeout);
      }
      this.changeTimeout = setTimeout(() => {
        if (this.loading) {
          this.resetLoader();
        } else {
          this.fullyLoaded = false;
          this.$set(this, "recurrences", []);
        }
      }, 300);
    },
    reload(){
      if (this.started){
        this.resetLoader();
      } else {
        this.started = true;
      }
    },
    async loadRecurrences(){
      this.loading = true;
      try {
        let response = await this.$axios.patch(
          '/form_requests/recurrents',
          this.filters,
          {
            params: {
              limit: this.limit,
              offset: this.recurrences.length,
            },
          }
        );
        this.recurrences = response.data;
        this.fullyLoaded = true;
      } catch (error){
        console.log(error);
        this.$showMessage('error', "Erro ao carregar recorrências");
        this.tryAgain = true;
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(){
        this.resetLoader();
      },
    }
  },
}
</script>
