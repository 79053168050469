var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pa-4 d-flex align-center"},[_c('h2',[_vm._v(" Formulários Recorrentes ")]),_c('v-spacer'),_c('FormFilter',{attrs:{"withStatus":false},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$refs.newFormDialog.open('recurrent')}}},[_vm._v(" Nova Recorrência ")])],1),_c('v-divider'),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"height":_vm.contentHeight-69,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.recurrences,"disable-pagination":"","loading-text":"Carregando... Por favor, aguarde","hide-default-footer":"","fixed-header":""},on:{"click:row":function($event){return _vm.$refs.formDialog.open($event.id)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.tryAgain)?_c('div',{staticClass:"py-5 d-flex flex-column align-center black--text"},[_vm._v(" Erro ao carregar recorrências "),_c('br'),_c('v-btn',{staticClass:"mt-4",attrs:{"small":"","depressed":"","color":"error"},on:{"click":function($event){_vm.tryAgain = false}}},[_vm._v(" tentar novamente ")])],1):[_vm._v(" Nenhuma recorrência encontrada ")]]},proxy:true},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_c('DocCategoryChips',{attrs:{"categories":item.categories}})]}},{key:"item.creation_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.creation_datetime))+" ")]}},{key:"item.last_sent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_sent ? _vm.$utils.formatDate(item.last_sent) : "-")+" ")]}},{key:"item.next_scheduled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.next_scheduled))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"justify-center align-center font-weight-medium",staticStyle:{"width":"100px"},attrs:{"label":"","small":"","color":_vm.getStatusColor(item)}},[_vm._v(" "+_vm._s(_vm.getStatusText(item))+" "),(item.status == 'pending')?[_vm._v(" "+_vm._s(item.current_question + "/" + item.total_questions)+" ")]:_vm._e()],2)]}},{key:"body.append",fn:function(){return [(!_vm.fullyLoaded && !_vm.tryAgain && _vm.started)?_c('tr',[_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('scroll-loader',{attrs:{"loader-method":_vm.loadRecurrences,"loader-disable":_vm.fullyLoaded}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"width":2}})],1)],1)]):_vm._e()]},proxy:true}],null,true)}),_c('RequestDialog',{ref:"formDialog",attrs:{"recurrent":""},on:{"updated":function($event){return _vm.resetLoader()}}}),_c('NewFormDialog',{ref:"newFormDialog",on:{"updated":function($event){return _vm.resetLoader()},"created":function($event){return _vm.$refs.requestDialog.open($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }