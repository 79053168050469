<template>
  <v-menu
    v-model="filtersMenu"
    offset-y
    min-width="500px"
    max-width="500px"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <v-badge
        :value="numActiveFilters > 0"
        :content="numActiveFilters"
        color="warning black--text"
        overlap left bordered
      >
        <v-btn
          v-on="on"
          color="primary"
          class="mr-4"
        >
          Filtrar
        </v-btn>
      </v-badge>
    </template>

    <!------------- FILTERS ------------->
    <v-card class="pa-3">
      <!-- text filter -->
      <v-text-field
        filled
        hide-details="auto"
        v-model="filters.text"
        label="Título ou descrição"
        prepend-inner-icon="mdi-magnify"
        clearable
      />
      <v-select
        v-if="withStatus"
        filled
        v-model="filters.status"
        :items="allStatus"
        label="Status"
        prepend-inner-icon="mdi-transit-connection-variant"
        hide-details
      />
      <!-- client -->
      <v-autocomplete
        filled
        hide-details="auto"
        v-model="filters.client_id"
        clearable
        :loading="loadingClients"
        :items="clients"
        item-text="name"
        item-value="id"
        prepend-inner-icon="mdi-account-filter"
        label="Cliente"
      />
      <!-- date interval picker -->
      <v-menu
        class="mb-auto"
        v-model="datePickerMenu"
        :close-on-content-click="false"
        transition="fade-transition"
        offset-overflow
        min-width="auto"
      >
        <template #activator="{ on }">
          <div @click="datePickerMenu = true">
            <v-text-field
              filled
              hide-details="auto"
              :value="dateRangeText"
              label="Selecionar Intervalo de Datas"
              prepend-inner-icon="mdi-calendar-multiselect"
              readonly
              clearable
              v-on="on"
              @click:clear="selectedDates = []"
            />
          </div>
        </template>
        <v-card>
          <v-date-picker
            v-model="selectedDates"
            type="month"
            range
            no-title
            color="primary"
            header-color="primary"
            :show-current="false"
            locale="pt-br"
          >
            <v-spacer />
            <v-btn
              small
              text
              color="primary"
              class="mt-n4"
              @click="datePickerMenu = false"
            >
              fechar
            </v-btn>
          </v-date-picker>
        </v-card>
      </v-menu>
      <!-- categories -->
      <DocCategorySelect
        v-model="filters.categories" 
        multiple
      />
    </v-card>
    <!------------- /FILTERS ------------->
  </v-menu>
</template>

<script>
import DocCategorySelect from "@/components/documents/DocCategorySelect.vue";

export default {
  name: "FormFilter",

  components: {
    DocCategorySelect,
  },

  props: {
    value: {default: {}},
    withStatus: {default: true, type: Boolean},
  },

  data(){
    return {
      filters: this.value || {},
      loadingClients: false,
      clients: [],

      datePickerMenu: false,
      filtersMenu: false,

      selectedDates: [],

      allStatus: [
        {text: "Todas",       value: null},
        {text: "Expiradas",   value: 'expired'},
        {text: "Respondidas", value: 'answered'},
        {text: "Pendentes",   value: 'pending'},
        {text: "Canceladas",  value: 'canceled'},
      ],
    };
  },

  computed: {
    numActiveFilters(){
      let count = 0;
      if (this.filters.text) ++count;
      if (this.filters.client_id) ++count;
      count += (this.filters.categories || []).length;
      count += (this.selectedDates || []).length;
      if (this.filters.status) ++count;
      return count;
    },
    dateRangeText() {
      var dates = (this.selectedDates || []).map(this.formatPickedDate);
      if (dates.length == 0) return "";
      if (dates.length == 1) return "A partir de " + dates[0];
      this.datePickerMenu = false;
      return "De " + dates.join(" a ");
    },
  },

  methods: {
    async loadClients() {
      this.loadingClients = true;
      try {
        const response = await this.$axios.get('/clients');
        this.clients = response.data;
      } catch (error){
        this.$showMessage("error", "Erro inesperado ao carregar os clientes");
      } finally {
        this.loadingClients = false;
      }
    },
    withDay(yearmonth, lastday){
      if (!yearmonth){
        return undefined;
      }
      if (!lastday){
        return yearmonth + '-01';
      }
      const [year, month] = yearmonth.split('-');
      const day = new Date(year, month, 0).getDate();
      return yearmonth +'-'+ day;
    },
    formatPickedDate(date) {
      const months = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];
      let [year, month] = date.split("-")
      return ((months[parseInt(month)-1] + " " + year));
    },
  },

  watch: {
    filters(v){
      this.$emit('input', v);
    },
    selectedDates(dates){
      this.$set(this.filters, 'initial_date', this.withDay(dates[0]));
      this.$set(this.filters, 'final_date', this.withDay(dates[1], true));
      this.$emit('input', this.filters);
    },
  },

  mounted(){
    this.loadClients();
  },

}
</script>
